@import 'src/styles/env';
@import 'src/styles/elements/typography';

.modal {
  margin-top: 20px;
}

.boxHelper {
  max-width: 70vw;
  padding: 10px;
}

.textHelper {
  @extend .helper;
  padding-bottom: 16px;
}
