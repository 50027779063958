@import 'src/styles/env';

.container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 24px);
  max-height: 19vh;
  min-height: 19vh;
  margin-bottom: 2vh;
  justify-content: space-between;
  position: relative;
  &:before {
    content: '';
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 200ms ease-in-out;
  }
  &.hasQuantity {
    &:before {
      box-shadow: inset 0 0 0 4px get-color('primary');
    }
  }
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.title {
  font-size: rem(14);
  font-weight: 400;
  margin: 0;
}

.variant {
  color: get-color('contrast');
  font-size: rem(10);
  font-weight: 300;
  margin: 0;
}

.image {
  align-items: center;
  display: flex;
  position: absolute;
  text-align: center;
  left: 26%;
  top: 34%;
  margin: 1vh 0 0;
  height: 7vh;
  svg {
    height: 90%;
    width: auto;
  }
}

.actions {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 0;
}
