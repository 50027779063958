@import 'env';
@import 'reset';
@import 'elements';
@import 'grid';

* {
  box-sizing: border-box;
  :before,
  :after {
    box-sizing: border-box;
  }
}

body {
  background: get-color('white');
  color: get-color('contrast', 'higher');
  font-family: $base-font-family;
  font-size: rem($base-font-size);
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
  text-rendering: optimizeLegibility;
  word-break: break-word;
  -webkit-overflow-scrolling: touch;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::selection {
  background: get-color('primary');
  color: #fff;
  opacity: 1;
}
