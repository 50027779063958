@import '../env';

a {
  color: get-color('primary');
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: get-color('contrast', 'higher');
  font-weight: 700;
  line-height: 1.15;
  margin: 1.5em 0 0.5em;
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: rem(36);
  font-weight: 300;
  margin: 0 0 0.5em;
}
@media (max-width: 500px) {
  h1 {
    font-size: rem(32);
  }
}

h2 {
  font-size: rem(28);
  font-weight: 500;
}

h3 {
  font-size: rem(18);
  font-weight: 500;
}

h4 {
  font-size: rem(16);
}

h5 {
  font-size: rem(14);
}

h6 {
  font-size: rem(14);
}

blockquote {
  font-style: italic;
}

.helper {
  margin-bottom: 1; margin-left: 2; font-size: '15px'; font-style: italic; color: get-color('primary', 'light');
}
