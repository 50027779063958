@import 'src/styles/env';

.container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 24px);
  max-height: 19vh;
  min-height: 19vh;
  margin-bottom: 2vh;
  justify-content: space-between;
  &.hasQuantity {
    &:before {
      box-shadow: inset 0 0 0 4px get-color('primary');
    }
  }
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.image {
  align-items: center;
  display: flex;
  height: 7.5vh;
  text-align: center;
  margin:auto;
  width:fit-content;
  svg {
    height: 90%;
    width: auto;
  }
}

.title {
  font-size: rem(14);
  font-weight: 400;
  margin: 0;
}

.variant {
  color: get-color('contrast');
  font-size: rem(12);
  font-weight: 300;
  margin: 0;
}

.actions {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 0;
}

.formInput {
  margin: 0 0;
  font-size: 10px;
}

.volume {
  margin: 0.8em 0 2em 0;
  width: 100%;
}
