@import 'src/styles/env';

.container {
  width: 100%;
  height: 90vh;
}

.download {
  height: 36px;
  padding-top: 10px;
  margin-left: 12px;
}

.popover {
  :global(.MuiPaper-root) {
    display: flex;
    flex-direction: column;
  }
}
