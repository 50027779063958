@import 'src/styles/env';

.room {
  scroll-margin-top: 15vh;
  margin-bottom: 50px;
  h2 {
    margin: 0 0 16px;
  }
}

.modal {
  position: 'absolute';
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  background-color: 'background.paper';
  border: '2px solid #000';
  box-shadow: 24;
}

.iconButton {
  padding: 8px;
  width: 2em !important;
  height: 2em !important;
}
