@import 'src/styles/env';

.container {
  max-height: 100vh;
  overflow: auto;
  padding: 16px 0 0;
  position: sticky;
  top: 0;
  z-index: 10;
}

.roomsTitle {
  margin-top: 0;
}

.furnituresAndBoxTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
}

.volume {
  font-size: 1.8em;
  margin-top: 0.5em;
}

.volumeBox {
  flex-shrink: 0;
  padding-left: 3em;
  margin-left: auto;
  align-self: right;
}

.roomWithFurnitureTitle {
  font-weight: 300;
  margin: 24px 0;
  text-transform: uppercase;
}

.box {
  border-bottom: 1px solid get-color('contrast', 'low');
  margin: 0 -16px;
  padding: 12px 16px;
}

.selectedFurnitureContainer {
  display: flex;
  flex-direction: column-reverse;
}

.selectedBoxContainer {
  background-color: #fff!important;
  display: flex;
  flex-direction: column-reverse;
}

.deleteBox {
  text-align: right;
  padding-right: 0;
}

