// Dimensions
$header-height: 65px;
$menu-desktop-width: 260px;

// Font
$base-font-size: 16; //in px
$base-font-family: 'Roboto', sans-serif;

// Colors
$color-key: 'base' !default;

$opacity-key: (
  'text': 0.8,
  'hover': 0.04,
  'active': 0.08,
);

$colors: (
  'primary': (
    'base': #6bb2a2,
    'light': #90c5b9,
    'lighter': #c7e2dc,
    'dark': #50857a,
  ),
  'success': (
    'base': #62b372,
  ),
  'error': (
    'base': #e02447,
  ),
  'warning': (
    'base': #ffd138,
  ),
  'contrast': (
    'base': #3c3f41,
    'low': #dcdcdc,
    'lower': #f0f0f0,
    'high': #272727,
    'higher': #121212,
  ),
  'white': (
    'base': #fff,
  ),
  'black': (
    'base': #000,
  ),
  'yellow': (
    'base': #f0d355,
  ),
  'blue': (
    'base': #1d1f28,
  ),
);
