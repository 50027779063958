input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: get-color('white');
  -webkit-box-shadow: 0 0 0 1000px lighten(get-color('contrast', 'high'), 15%) inset;
  transition: background-color 5000s ease-in-out 0s;
}
