@import 'src/styles/env';

.header {
  border-bottom: 4px solid get-color('primary');
  padding: 14px 0;
}

.title {
  margin: 0;
}
