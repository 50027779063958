// Breakpoints
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-x-large: 1200px;
$breakpoint-huge: 1400px;

// Media queries
// Mobile-first media queries (min-width only)
:export {
  small: $breakpoint-small;
  medium: $breakpoint-medium;
  large: $breakpoint-large;
  xLarge: $breakpoint-x-large;
  huge: $breakpoint-huge;
}
