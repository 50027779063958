@import 'variables';

@function get-color($name: 'primary', $variant: $color-key, $opacity: 1) {
  $color: null;

  // Get the color spectrum
  $color-spectrum: map-get($colors, $name);

  // Get the color variant
  @if $color-spectrum {
    $color: map-get($color-spectrum, $variant);
  }

  // Get the alpha setting
  $alpha: if(type-of($opacity) == 'number', $opacity, map-get($opacity-key, $opacity));

  // Set the alpha of the color
  @if $alpha {
    $color: rgba($color, $alpha);
  }

  @return $color;
}

@function rem($target) {
  @return ($target / $base-font-size) + 0rem;
}
