@import 'src/styles/env';

.navigation {
  background: get-color('white');
  margin-bottom: 32px;
  position: sticky;
  top: 0;
  z-index: 20;
}

.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 16px 0;
  li {
    margin: 0 3px 6px 3px;
  }
  button {
    width: 174px;
  }
  .add {
    width: unset;
  }
  @media ($mq-only-phone) {
    overflow-x: auto;
    flex-wrap: nowrap;
    justify-content: start;
    height: 100%;
    width: 100%;

    button {
      height: 100%;
      width: 210px;
    }
  }
}
