@import 'src/styles/env';

.container {
  background: get-color('contrast', 'lower');
  border-bottom: 1px solid get-color('contrast', 'low');
  margin: 0 -16px;
  padding: 12px 16px;
}

.boxContainer {
  border-top: 1px solid get-color('contrast', 'low');
  border-bottom: 1px solid get-color('contrast', 'low');
  margin: 0 -16px;
  padding: 12px 16px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: rem(16);
  font-weight: 400;
  margin: 0;
}

.volume {
  flex-shrink: 0;
  margin-left: auto;
  align-self: center;
}

.variant {
  color: get-color('contrast');
  font-size: rem(14);
  font-weight: 300;
  margin: 0;
  margin-left: 5px;
}

.iconButton {
  padding: 8px;
  width: 2em !important;
  height: 2em !important;
}

.align-right {
  margin: auto;
}

.menu {
  padding: 16px;
}

.modal {
  position: 'absolute';
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  background-color: 'background.paper';
  border: '2px solid #000';
  box-shadow: 24;
}

.linethrough {
  text-decoration: line-through;
}

.actionBox {
  color: get-color('primary');
  padding-left: 1em;
}
