.container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.label {
  font-weight: 400;
  margin: 0;
}

.actions {
  align-items: center;
  display: flex;
}
